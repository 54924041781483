import { graphql, useStaticQuery } from "gatsby"

import Fields from '../components/fields'
import Layout from '../layouts'
import PageHeader from '../components/page/header'
import Photos from '../components/photos'
import React from 'react'
import Seo from '../components/seo'
import Text from '../components/text'

const Therapy = () => {
  const { pageHeaderImage, fields, photoOne, photoTwo } = useStaticQuery(
    graphql`
      {
        pageHeaderImage: imageSharp(resize: {originalName: {eq: "ernaehrungsberatung.jpg"}}) {
          gatsbyImageData(
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1000
          )
        }

        photoOne: imageSharp(resize: {originalName: {eq: "ernaehrungsberatung-seminar.jpg"}}) {
          gatsbyImageData(
            height: 710
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1110
          )
        }

        photoTwo: imageSharp(resize: {originalName: {eq: "ernaehrungsberatung-lebensmittel.jpg"}}) {
          gatsbyImageData(
            height: 710
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1110
          )
        }

        fields: allErnaehrungsberatungJson {
          edges {
            node {
              name
              copy
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo
        title='Ernährungsberatung'
        currentUrl='/ernaehrungsberatung'
      />
      <PageHeader
        headline='Ernährungsberatung'
        copy='Nach neusten Erkenntnissen und typgerecht auf sie abgestimmt erhalten Sie eine Gesundheits- und Ernährungsberatung um Ihre Ziele effektiv und nachhaltig zu erreichen.'
        image={pageHeaderImage}
      />
      <Text text='Abgestimmt auf Ihre persönlichen Ziele entwickeln wir gemeinsam ein alltagstaugliches Ernährungskonzept. Informationen, praktische Tipps und eine individuelle Betreuung helfen, das Erlernte langfristig umzusetzen – in Familie und Beruf.'/>
      <Fields fields={fields?.edges.map((node) => node.node )}/>
      <Text text={`
Eine gesunde Ernährung ist der Schlüssel zu einem gesunden Körper. Die Nahrung und die darüber aufgenommenen Stoffe bilden die Grundlage für sämtliche Prozesse, die in unserem Körper ablaufen.

Eine falsche Ernährung kann nicht nur Übergewicht, Gelenkschmerzen oder Verspannungen auslösen, sondern eine falsche Energieaufnahme kann weitaus mehr Störungen im Körper hervorrufen.
Im Ernährungs-Coaching geht es darum, Ernährungsprobleme zu erkennen, zu analysieren und – je nach persönlichem Bedarf – langfristig die Ernährung begleitend umzustellen.
      `}/>
      <Photos photos={[{ photo: photoOne, alt: "Die Ernährungs ist eine wichtige Grundlage für Gesundheit" }]}/>
      <Text text={`
Um eventuelle Einflussfaktoren aufzudecken wird für eine Woche ein Ernährungstagebuch geführt und wir testen mittels kinesiologischer Muskeltests, was es braucht um Ihren Körper optimal zu unterstützen. Im Anschluss erstellen wir Ihren Plan und Sie können mit der Umsetzung beginnen. In regelmäßigen Abständen prüfen wir die Ergebnisse und klären offene Fragen. Sollten sich im ersten Termin noch weitere Fragen oder Problematiken darstellen, berate ich Sie auch gern zu einem gesunden Lebensstil. Oft sind es einfache Dinge, die uns Energie, guten Schlaf oder Wohlbefinden rauben.

Es kommen natürliche Lebensmittel auf den Tisch, die nicht industriell verarbeitet wurden und möglichst regional sind. Ihre neue auf Sie angepasste Ernährung hält den Körper fit und gesund. Es geht nicht um den Verzicht, sondern um hochwertige Kohlehydrate und Proteine, gute Fette, naturbelassenes Obst und Gemüse.
      `}/>
      <Photos photos={[{ photo: photoTwo, alt: "Eine Auswahl an nahrhaften Lebensmitteln" }]}/>
      <Text text={`
Ich unterstütze Sie unter anderem durch:

- Die sukzessive Änderung Ihrer Ernährungsgewohnheiten
- Die Anpassung der Mahlzeiten an Ihre Lebensumstände
- Beratung zur Verhaltensänderung hinsichtlich Ihres Essverhaltens und -gewohnheiten sowie Ihrer körperlichen Betätigung
- Kontinuierliche Begleitung im Rahmen von persönlichen Terminen, wodurch eine stetige Erfolgsoptimierung ermöglicht wird
- Reflexion Ihres Einkaufverhaltens
- Coaching bei Allergien und Lebensmittelunverträglichkeiten
- Ernährungs-Coaching und Therapie bei ernährungsbedingten Krankheiten (Magenproblemen, Leberproblemen, Verdauungsproblemen)

Sie definieren Ihr Ziel und ich begleite Sie mit großem Engagement, viel Empathie und den notwendigen Kenntnissen auf diesem Weg.
      `}/>
    </Layout>
  )
}

export default Therapy
